import React from "react";
import Avatar from '@mui/material/Avatar';
import './css/Footer.css';

const Footer = () => {

    return <div id="footerWrapper">
        <div id="footer">
            <div id="footerInfo">
                <Avatar id="footerAvatar" src="/angeloronAvatar.jpg" alt="Ange Loron" />
                {/* <div id="footerName">Ange Loron</div> */}
            </div>
            <div id="footerEmail">Handmade with <span style={{ color: 'red', fontSize: '15px' }}>❤</span> in <a href="https://reactjs.org/" id="reactlink">react JS</a></div>
        </div>
        <div id="copyights">Copyrights © {new Date().getFullYear()} All Rights Reserved</div>
    </div>
}

export default Footer;