import React, { useState } from "react";
import { Scrollbar } from 'react-scrollbars-custom';
import Footer from '../Footer';
import '../css/Home.css';

const Home = () => {
    const text = " EXTRAORDINARY";

    const [hovered, setHovered] = useState(false);

    const onEnter = () => setHovered(true);
    const onLeave = () => setHovered(false);

    return (
        <Scrollbar noScrollX="true">
            <div id="wrapper">
                <div id="textLine">
                    <h1 className="lineUp" id="name">ANGE LORON</h1>
                    <h3 className="lineUp">
                        UNITING TALENTS TO ACHIEVE THE{" "}
                        <span
                            className={`extraordinary ${hovered ? 'hovered' : ''}`}
                            onMouseEnter={onEnter}
                            onMouseLeave={onLeave}
                        >
                            {text.split('').map((char, idx) => (
                                <span key={idx} style={{ '--i': idx }}>
                                    {char}
                                </span>
                            ))}
                        </span>
                    </h3>
                </div>
            </div>
            <Footer />
        </Scrollbar>
    );
};

export default Home;
