import React from "react";
import Footer from '../Footer';
import { Scrollbar } from 'react-scrollbars-custom';
import '../css/Videos.css';

const videosArray = [
    "https://www.youtube.com/embed/T8ObSmw_OjY",
    "https://www.youtube.com/embed/u4STCTUhSaA",
    "https://www.youtube.com/embed/FciAK5Tb5Lw",
    "https://www.youtube.com/embed/YQfy3JSnrpo",
    "https://www.youtube.com/embed/0Sd8Vo8K9Bo"
]

const Videos = () => {
    return <Scrollbar noScrollX ="true">
        <div id="videoWrapper">
            {videosArray.map((x, i) => <iframe key={i} title={`video${i}`} src={x} frameBorder="0" allowFullScreen />)}
        </div>
        <Footer />
    </Scrollbar>
}

export default Videos;