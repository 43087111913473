import React from "react";
import CameraIcon from '@mui/icons-material/CameraAlt';
import VideoIcon from '@mui/icons-material/Videocam';
import CodeIcon from '@mui/icons-material/Code';
import HomeIcon from '@mui/icons-material/Home';
import AccountIcon from '@mui/icons-material/AccountCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

export const menu_array = [
    { name: 'HOME', icon: <HomeIcon /> },
    { name: 'PHOTOS', icon: <CameraIcon /> },
    { name: 'VIDEOS', icon: <VideoIcon /> },
    { name: 'CODES', icon: <CodeIcon /> },
    { name: 'BLOG', icon: <LibraryBooksIcon /> },
    { name: 'CONTACT', icon: <AccountIcon /> },
];