import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    Container,
    Grid,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { stripHtml } from '../../functions';
import { Scrollbar } from 'react-scrollbars-custom';
import Footer from '../Footer';

const useStyles = makeStyles({
    card: {
        maxWidth: 450,
        maxHeight: 400,
        margin: 20,
    },
    cardActionArea: {
        height: "100%",
        justifyContent: "start",
        display: "flex",
        flexDirection: "column",
    }
});

const Blog = () => {

    const [articles, loadArticles] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        Promise.all([
            axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@angeit'),
            axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@angelife')
        ])
            .then((responses) => {
                // Merge the results of both responses into one array
                const combinedArticles = [
                    ...responses[0].data.items,
                    ...responses[1].data.items
                ];

                // Sort the combined array by 'pubDate' in descending order (newest first)
                const sortedArticles = combinedArticles.sort((a, b) => new Date(b.pubDate) - new Date(a.pubDate));
                loadArticles(sortedArticles);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return <Scrollbar noScrollX="true">
        <Container style={{ minHeight: "calc(100vh - 240px)" }}>
            <Grid container justifyContent="center">
                {articles.map((x, i) =>
                    <Card key={i} className={classes.card} style={{ width: '100%' }}>
                        <CardActionArea className={classes.cardActionArea} onClick={() => window.open(x.link, '_blank')}>
                            <CardMedia
                                component="img"
                                alt={x.title}
                                height="170"
                                image={x.description.toString().match(/<img[^>]+src="([^">]+)"/)[1]}
                                title={x.title}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2" style={{ height: "auto" }}>
                                    {x.title}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {stripHtml(x.description).split('Continue reading', 1)[0]}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                )}
            </Grid>
        </Container>
        <Footer />
    </Scrollbar>
}

export default Blog;