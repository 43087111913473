import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const CustomLink = ({ to, onClick = () => {}, ...rest }) => {
  const navigate = useNavigate();

  return (
    <div
      {...rest}
      onClick={(event) => {
        onClick(event);
        navigate(to);
      }}
    />
  );
};

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default CustomLink;
