import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { SwipeableDrawer, SpeedDial, SpeedDialAction } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Menu from './Menu';
import MenuDrawer from './MenuDrawer';
import Photos from './Views/Photos';
import Videos from './Views/Videos';
import Codes from './Views/Codes';
import Blog from './Views/Blog';
import Home from './Views/Home';
import Contact from './Views/Contact';
import './css/Main.css';

const Main = () => {
    const [dial, toggleDial] = React.useState(false);
    const [drawer, toggleDrawer] = React.useState(false);
    
    // Get location using useLocation hook
    const location = useLocation();

    const actions = [
        {
            icon: <FacebookIcon />,
            name: 'Facebook',
            url: 'https://www.facebook.com/loronange'
        },
        {
            icon: <TwitterIcon />,
            name: 'Twitter',
            url: 'https://twitter.com/ange_loron'
        },
        {
            icon: <InstagramIcon />,
            name: 'Instagram',
            url: 'https://www.instagram.com/angeloronphoto/'
        },
    ];

    return (
        <>
            <Menu location={location} toggleDrawer={toggleDrawer} />
            <div className="mainwrapper">
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/home' element={<Home />} />
                    <Route path='/photos' element={<Photos />} />
                    <Route path='/videos' element={<Videos />} />
                    <Route path='/codes' element={<Codes />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/contact' element={<Contact />} />
                </Routes>

                <SpeedDial
                    style={{ position: "absolute", right: '20px', bottom: '15px' }}
                    ariaLabel="SpeedDial"
                    color="secondary"
                    icon={<ShareIcon />}
                    onClose={() => toggleDial(false)}
                    onOpen={() => toggleDial(true)}
                    open={dial}
                    direction='up'
                >
                    {actions.map(action => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => { toggleDial(false); window.open(action.url, '_blank'); }}
                            className={action.name}
                        />
                    ))}
                </SpeedDial>

                <SwipeableDrawer open={drawer} onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)}>
                    <MenuDrawer location={location} toggleDrawer={toggleDrawer} />
                </SwipeableDrawer>
            </div>
        </>
    );
}

export default Main;
